<template>
  <div id="task-list">
    <div v-if="loaded && stages.length" class="container">
      <h4 class="title">
        Task List
      </h4>
      <div class="task-list">
        <b-button
          v-for="stage in stages"
          :key="stage.id"
          class="task-container"
          :aria-label="stage.accounts_stage?.current_slide_id ? 'In-Progress' : 'New'"
          @click="goToStage(stage)"
        >
          <div class="badges">
            <div v-if="stage.accounts_stage?.current_slide_id" class="badge in-progress">
              In-Progress
            </div>
            <div v-else class="badge new">
              New
            </div>
          </div>
          <div class="stage">
            <span class="stage-title">
              {{ stage.title }}
            </span>

            <fa-icon icon="chevron-right" class="icon" />
          </div>
        </b-button>
      </div>
      <b-button class="railyard-button" @click="goToRailyard()">
        Explore Additional Tasks

        <fa-icon icon="chevron-right" class="icon" size="xs" />
      </b-button>
    </div>
    <ct-centered-spinner v-else-if="!loaded" class="mb-5">
      Loading Tasks...
    </ct-centered-spinner>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskList',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner.vue'),
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('stagelineV3', [
      'stages',
    ]),
  },
  async mounted() {
    try {
      await this.getStages({ limit: 4, include: 'accounts_stage', company_id: this.currentCompany.id })
    } catch {
      // do nothing for now
    } finally {
      this.loaded = true
    }
  },
  methods: {
    ...mapActions('stagelineV3', [
      'getStages',
    ]),
    async goToStage(stage) {
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.currentCompany.id,
          stageId: stage.id,
        },
      })
    },
    async goToRailyard() {
      await this.$router.push({ name: 'available-stages', params: {
          companyId: this.currentCompany.id,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
#task-list {
  .task-list::-webkit-scrollbar {
    display: none;
  }

  .container {
    margin-bottom: 5em;
    padding: 0;
  }

  .railyard-button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 1em;
    font-size: 0.75em;
    float: right;
    padding: 0;
    background-color: $white;
    color: $ct-ui-primary;
    border: none;

    &:hover {
      transform: translateY(-1px);
    }

    &:focus {
      box-shadow: none;
      text-decoration: underline;
      text-decoration-color: $ct-ui-primary;
      text-decoration-style: solid;
    }
  }

  .task-list {
    display: flex;
    overflow-x: auto;
    gap: 0.5em;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;

    .task-container {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      padding: 1em;
      border: 2px solid lightgray;
      border-radius: 0.5em;
      margin-top: 0.5em;
      background-color: white;

      .badges {
        min-height: 1em;
        margin-bottom: 0.75em;
        display: flex;
        flex-wrap: wrap;
        max-width: 12em;
        gap: 0.25em;

        .badge {
          font-size: 0.625em;
          padding: 0.5em;
          border-radius: 1em;
        }

        .in-progress {
          background: #33B3AF40;
          color: #009FBF;
        }

        .new {
          background: #33B34A40;
          color: #009F4F;
        }

        .urgent {
          background: #F93F3629;
          color: #CB2328;
        }
      }

      .stage {
        color: black;
        font-size: 1.5em;
        margin-top: auto;

        .stage-title {
          margin-right: 1em;
          margin-bottom: 0.5em;
          font-weight: 600;
        }
      }

      &:hover {
        border-color: $ct-ui-primary;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);

        .icon {
          color: $ct-ui-primary;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
