var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "task-list" } },
    [
      _vm.loaded && _vm.stages.length
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c("h4", { staticClass: "title" }, [
                _vm._v("\n      Task List\n    "),
              ]),
              _c(
                "div",
                { staticClass: "task-list" },
                _vm._l(_vm.stages, function (stage) {
                  return _c(
                    "b-button",
                    {
                      key: stage.id,
                      staticClass: "task-container",
                      attrs: {
                        "aria-label": stage.accounts_stage?.current_slide_id
                          ? "In-Progress"
                          : "New",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goToStage(stage)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "badges" }, [
                        stage.accounts_stage?.current_slide_id
                          ? _c("div", { staticClass: "badge in-progress" }, [
                              _vm._v("\n            In-Progress\n          "),
                            ])
                          : _c("div", { staticClass: "badge new" }, [
                              _vm._v("\n            New\n          "),
                            ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "stage" },
                        [
                          _c("span", { staticClass: "stage-title" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(stage.title) +
                                "\n          "
                            ),
                          ]),
                          _c("fa-icon", {
                            staticClass: "icon",
                            attrs: { icon: "chevron-right" },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
              _c(
                "b-button",
                {
                  staticClass: "railyard-button",
                  on: {
                    click: function ($event) {
                      return _vm.goToRailyard()
                    },
                  },
                },
                [
                  _vm._v("\n      Explore Additional Tasks\n\n      "),
                  _c("fa-icon", {
                    staticClass: "icon",
                    attrs: { icon: "chevron-right", size: "xs" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : !_vm.loaded
        ? _c("ct-centered-spinner", { staticClass: "mb-5" }, [
            _vm._v("\n    Loading Tasks...\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }